const types = {
  SET_SPORTS_LIST: 'SET_SPORTS_LIST',
  GET_EVENT_DETAILS: 'GET_EVENT_DETAILS',
  GET_EVENTS: 'GET_EVENTS',
  CHANGE_WIDTH: "CHANGE_WIDTH",
  ADD_MATCHES: "ADD_MATCHES",
  SELECT_LIVE: "SELECT_LIVE",
  CLEAR_ALL_BETS: "CLEAR_ALL_BETS",
  CHANGE_MENU: "CHANGE_MENU",
  CHANGE_ACCENT: "CHANGE_ACCENT",
  CHANGE_THEME: "CHANGE_THEME",
  CHANGE_SEND_BUTTON: 'CHANGE_SEND_BUTTON',
  CHANGE_FONT_SIZE: "CHANGE_FONT_SIZE",
  CHANGE_INTERFACE_VIEW: "CHANGE_INTERFACE_VIEW",
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
  CHANGE_DATE_FORMAT: "CHANGE_DATE_FORMAT",
  CHANGE_TIMEZONE: 'CHANGE_TIMEZONE',
  CHANGE_DEALING_VIEW: 'CHANGE_DEALING_VIEW',
  ADD_BETSLIP: 'ADD_BETSLIP',
  EDIT_BETSLIP: 'EDIT_BETSLIP',
  SET_SPORTS_ID: 'SET_SPORTS_ID',
  SET_MATCH_ID: 'SET_MATCH_ID',
  SET_LIVE_WIDGET_MATCH_ID: 'SET_LIVE_WIDGET_MATCH_ID',
  SET_TOKEN: 'SET_TOKEN',
  SET_PUSHER: 'SET_PUSHER',
  MUTATE_DETAILS: 'MUTATE_DETAILS',
  MUTATE_LIVE: 'MUTATE_LIVE',
  MUTATE_EVENTS: 'MUTATE_EVENTS',
  MUTATE_ALL_EVENTS: 'MUTATE_ALL_EVENTS',
  REFRESH_SPORTS: 'REFRESH_SPORTS',
  REFRESH_MATCH: 'REFRESH_MATCH',
  FILTER_EVENTS:'FILTER_EVENTS',
  GET_USER_INFO: 'GET_USER_INFO',
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  TOGGLE_LOB: 'TOGGLE_LOB',
  TOGGLE_TOAST: 'TOGGLE_TOAST',
  TOGGLE_TOAST_TEXT: 'TOGGLE_TOAST_TEXT',
  TOGGLE_ERROR_TOAST_TEXT: 'TOGGLE_ERROR_TOAST_TEXT',
  TOGGLE_ERROR_TOAST: 'TOGGLE_ERROR_TOAST',
  TOGGLE_LOADER: 'TOGGLE_LOADER',
  CHANGE_FAVORITE: 'CHANGE_FAVORITE',
  SET_FAVFILTER: 'SET_FAVFILTER',
  SET_BETFILTER: 'SET_BETFILTER',
  SET_PAYMENT_SYSTEMS: 'SET_PAYMENT_SYSTEMS',
  SET_NON_CRYPTO_PAYMENT_SYSTEMS: 'SET_NON_CRYPTO_PAYMENT_SYSTEMS',
  SET_CURRENCIES: 'SET_CURRENCIES',
  SET_TRANSACTIONS: 'SET_TRANSACTIONS',
  SET_CHAT_ID: 'SET_CHAT_ID',
  SET_QUOTE_DATA: 'SET_QUOTE_DATA',
  SET_BET_QUOTES: 'SET_BET_QUOTES',
  SET_COMPETITION_ID: "SET_COMPETITION_ID",
  SET_COMPETITIONS_DICT: 'SET_COMPETITIONS_DICT',
  SET_PARTICIPANTS: 'SET_PARTICIPANTS',
  SET_COMMA_SEPARATOR: 'SET_COMMA_SEPARATOR',
  SET_DISPLAY_HANDICAP: 'SET_DISPLAY_HANDICAP',
  SET_DISPLAY_ZERO_HANDICAP: 'SET_DISPLAY_ZERO_HANDICAP',
  SET_HEADER_COLORFUL: 'SET_HEADER_COLORFUL',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_ALL_INTERFACE_SETTINGS: 'SET_ALL_INTERFACE_SETTINGS'
};
export default types;
